<template>
  <div class="seed-list-wrapper">
    <small v-if="breachedCredLimit" class="text-orange font-weight-bold">
      You added more domains that the credentials band allows for. We've the disabled some of them
    </small>

    <sw-datatable
      silent
      :headers="headers"
      :data="seeds"
      :hide-footer="true"
      :has-selection="false"
      :clickable="false"
    >
      <!-- make modules togelable -->
      <template v-slot:item.easm="{ item }">
        <sw-switch
          v-model:model-value="item.easm"
          @update:model-value="setDisableSeed(item, 'easm', $event)"
        />
      </template>

      <template v-slot:item.dark_web="{ item }">
        <sw-switch
          :model-value="item.dark_web"
          @update:model-value="setDisableSeed(item, 'dark_web', $event)"
        />
      </template>

      <template v-slot:item.data_leakage="{ item }">
        <sw-switch
          :model-value="item.data_leakage"
          @update:model-value="setDisableSeed(item, 'data_leakage', $event)"
        />
      </template>

      <template v-slot:item.social_media="{ item }">
        <sw-switch
          :model-value="item.social_media"
          @update:model-value="setDisableSeed(item, 'social_media', $event)"
        />
      </template>

      <template v-slot:item.credentials="{ item }">
        <sw-switch
          :model-value="item.credentials"
          :disabled="selectedMax && !item.credentials"
          :tooltip="
            selectedMax && !item.credentials
              ? 'You already enabled all domains the band allows for. Disable another or change band if you wanna enable this one'
              : undefined
          "
          @update:model-value="setDisableSeed(item, 'credentials', $event)"
        />
      </template>

      <!-- Remove seed from list -->
      <template v-slot:item.actions="{ item }">
        <v-btn variant="text" size="x-small" @click.stop="$emit('click', item.domain)">
          <v-icon>fa-regular fa-trash</v-icon>
        </v-btn>
      </template>
    </sw-datatable>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, watch, ref } from "vue";
  import { SwDatatable, SwSwitch } from "sweepatic-shared/components";
  import type { DataTableHeader } from "sweepatic-shared/typings/datatable";

  import { ModuleType } from "@/typings/scope";
  import type { Band, ScopeModuleWithName, Seed } from "@/typings/scope";
  import { useStore } from "@/composables/store";
  import { capitalizeModule, formatModuleName } from "@/utils/formatters";

  let headers: DataTableHeader[] = [];

  const $emit = defineEmits<{
    (e: "click", domain: string): void;
    (e: "update:seed-module", seed: Seed): void;
  }>();

  const props = defineProps({
    seeds: { type: Array as PropType<Seed[]>, required: true },
    modules: { type: Array as PropType<ScopeModuleWithName[]>, default: () => [] },
  });

  let hasCreds = false;
  let totalAllowedDomains = 0;
  let numSelectedCreds = 0;

  const breachedCredLimit = ref(false);
  const selectedMax = ref(false);

  const $vuex = useStore();

  const setDisableSeed = (seed: Seed, module: keyof typeof ModuleType, value: boolean) => {
    if (value) {
      if (seed.modules && !seed.modules.includes(module)) {
        seed.modules.push(props.modules.find((m) => m.name === module)!.name);
      } else {
        seed.modules = [props.modules.find((m) => m.name === module)!.name];
      }
    } else {
      seed.modules = (seed.modules || []).filter((mod) => mod !== module);
    }

    if (hasCreds) {
      numSelectedCreds = value ? numSelectedCreds + 1 : numSelectedCreds - 1;
      selectedMax.value = numSelectedCreds >= totalAllowedDomains;
    }

    // @ts-ignore ts being a lil bitch
    seed[module] = value;
    seed.modules = seed.modules.filter((m) => m !== module);
    $emit("update:seed-module", seed);
  };

  watch(
    () => props.modules,
    (modules) => {
      let easmEnabled = false;
      let darkWebEnabled = false;
      let dataLeakageEnabled = false;
      let socialMediaEnabled = false;
      let creds: ScopeModuleWithName | null = null;

      const moduleHeadersList = modules.reduce<DataTableHeader[]>((result, module) => {
        if (module.name === ModuleType.outscan) {
          return result;
        }

        if (module.name === ModuleType.easm) {
          easmEnabled = true;
        }

        if (module.name === ModuleType.dark_web) {
          darkWebEnabled = true;
        }

        if (module.name === ModuleType.data_leakage) {
          dataLeakageEnabled = true;
        }

        if (module.name === ModuleType.social_media) {
          socialMediaEnabled = true;
        }

        if (module.name === ModuleType.credentials) {
          creds = module;
          hasCreds = true;
        }

        result.push({
          title:
            module.name === ModuleType.easm
              ? capitalizeModule(module.name)
              : formatModuleName(module.name),
          value: module.name,
          sortable: false,
        });

        return result;
      }, []);

      headers = [
        { title: "Domain", value: "domain", sortable: true },
        ...(!easmEnabled ? [{ title: "Asset Manager", value: "easm", sortable: false }] : []),
        ...(!darkWebEnabled ? [{ title: "Dark Web", value: "dark_web", sortable: false }] : []),
        ...(!dataLeakageEnabled
          ? [{ title: "Data Leakage", value: "data_leakage", sortable: false }]
          : []),
        ...(!socialMediaEnabled
          ? [{ title: "Social Media", value: "social_media", sortable: false }]
          : []),
        ...moduleHeadersList,
        { title: "", value: "actions", sortable: false, width: "32px" },
      ];

      if (creds) {
        // @ts-ignore TS being a bitch
        const { domain }: Band = $vuex.getters["scopes/bands"][creds.config!.band];
        totalAllowedDomains = domain;

        if (props.seeds.length > domain) {
          props.seeds.forEach((s, idx) => {
            if (idx >= domain) {
              s.credentials = false;
              s.modules = s.modules ? s.modules.filter((m) => m !== "credentials") : [];
              $emit("update:seed-module", s);
            }
          });

          selectedMax.value = true;
          numSelectedCreds = domain;
          breachedCredLimit.value = true;
        } else {
          numSelectedCreds = props.seeds.length;
          selectedMax.value = false;
        }
      }
    },
    { deep: true, immediate: true },
  );
</script>

<style lang="scss" scoped>
  .seed-list-wrapper {
    max-height: 530px;
    overflow: auto;
  }
</style>
