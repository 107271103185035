import type { KeyObject } from "sweepatic-shared/typings/misc";

import { ModuleType } from "@/typings";

export const CONFIGURABLE_MODULES = [
  ModuleType.easm,
  ModuleType.credentials,
  ModuleType.dark_web,
  ModuleType.data_leakage,
  ModuleType.social_media,
  ModuleType.outscan,
];

export const EDITABLE_MODULES = [
  ModuleType.credentials,
  ModuleType.dark_web,
  ModuleType.data_leakage,
  ModuleType.social_media,
  ModuleType.outscan,
];

export const DISABLED_IN_DEMO = [
  ModuleType.dark_web,
  ModuleType.data_leakage,
  ModuleType.social_media,
];

export const LICENSED_MODULE_TYPES = [
  ModuleType.credentials,
  ModuleType.dark_web,
  ModuleType.data_leakage,
  ModuleType.social_media,
];

export const licenseDurations: KeyObject[] = [
  { value: "month", title: "1 Month" },
  { value: "year", title: "1 Year" },
];

export const MODULE_LOGO: Record<ModuleType, string> = {
  files: "/assets/img/modules/file-regular.svg",
  easm: "/assets/img/modules/easm.png",
  credentials: "/assets/img/modules/credentials.svg",
  dark_web: "/assets/img/modules/dark-web.svg",
  data_leakage: "/assets/img/modules/data-leakage.svg",
  social_media: "/assets/img/modules/social-media.svg",
  outscan: "/assets/img/modules/outscan.avif",
};

export const MODULE_TYPE_NAMES: Partial<Record<ModuleType, string>> = {
  [ModuleType.dark_web]: "Dark Web",
  [ModuleType.data_leakage]: "Data Leakage",
  [ModuleType.social_media]: "Social Media",
};
