<template>
  <div>
    <h4>Modules</h4>

    <v-container fluid>
      <v-row>
        <v-col v-for="(module, moduleType) in modules" :key="moduleType" cols="auto">
          <v-card
            :class="{ disabled: !module!.enabled && !module!.licensed }"
            class="mx-auto relative rounded"
            width="280"
            variant="outlined"
          >
            <v-card-item class="opacity-on-disable">
              <div>
                <div class="text-center">
                  <img height="50" alt="" :src="MODULE_LOGO[moduleType]" />
                </div>
                <div class="text-h6 mb-1 text-center">
                  {{ moduleType.replaceAll("_", " ").toUpperCase() }}
                </div>
              </div>
            </v-card-item>

            <v-card-actions>
              <div
                class="d-flex align-center mx-auto"
                style="gap: 2%"
                v-if="CONFIGURABLE_MODULES.includes(moduleType)"
              >
                <small
                  v-if="DISABLED_IN_DEMO.includes(moduleType) && type === 'demo'"
                  class="font-italic"
                >
                  Cannot enable {{ MODULE_TYPE_NAMES[moduleType] || "" }} for demo scopes
                </small>

                <template v-else>
                  <sw-tooltip :tooltip="moduleButtonDescriptions[moduleType]?.enable">
                    <v-btn
                      variant="outlined"
                      class="flex-grow-1 rounded"
                      @click="toggleEnable(moduleType)"
                    >
                      {{ module!.enabled ? "ENABLED" : "DISABLED" }}
                      <v-icon
                        class="ml-2 mt-n1"
                        :class="{
                          'text-green': module!.enabled,
                          'text-red': !module!.enabled,
                        }"
                      >
                        fa-regular {{ module!.enabled ? "fa-check" : "fa-xmark" }}
                      </v-icon>
                    </v-btn>
                  </sw-tooltip>

                  <sw-tooltip
                    v-if="moduleType !== ModuleType.outscan"
                    :tooltip="moduleButtonDescriptions[moduleType]?.license"
                  >
                    <v-btn
                      variant="outlined"
                      class="flex-grow-1 rounded"
                      @click="toggleLicensed(moduleType, module as ScopeModule)"
                    >
                      LICENSED
                      <v-icon
                        class="ml-2 mt-n1"
                        :class="{
                          'text-green': module!.licensed,
                          'text-red': !module!.licensed,
                        }"
                      >
                        fa-regular {{ module!.licensed ? "fa-check" : "fa-xmark" }}
                      </v-icon>
                    </v-btn>
                  </sw-tooltip>
                </template>
              </div>
            </v-card-actions>

            <div v-if="module!.enabled || module!.licensed" class="edit-btn">
              <div>
                <v-btn
                  v-if="EDITABLE_MODULES.includes(moduleType)"
                  class="rounded"
                  variant="text"
                  size="small"
                  @click="openEdit(moduleType, module as ScopeModule)"
                >
                  <v-icon size="small" class="mr-2">fa-regular fa-pen</v-icon>
                </v-btn>
              </div>

              <div class="d-flex">
                <div
                  v-if="moduleType === ModuleType.credentials"
                  :class="{ 'text-decoration-line-through ': !module!.licensed }"
                  class="mt-1 mr-1"
                >
                  {{ (module!.config as CredentialsConfig)?.band_name || "" }}
                </div>

                <div
                  v-if="moduleType === ModuleType.dark_web"
                  :class="{ 'text-decoration-line-through ': !module!.licensed }"
                  class="mt-1 mr-1"
                >
                  {{ (module!.config as DarkWebConfig)?.band_name || "" }}
                </div>

                <div
                  v-if="moduleType === ModuleType.data_leakage"
                  :class="{ 'text-decoration-line-through ': !module!.licensed }"
                  class="mt-1 mr-1"
                >
                  {{ (module!.config as DataLeakageConfig)?.band_name || "" }}
                </div>

                <div
                  v-if="moduleType === ModuleType.social_media"
                  :class="{ 'text-decoration-line-through ': !module!.licensed }"
                  class="mt-1 mr-1"
                >
                  {{ (module!.config as SocialMediaConfig)?.band_name || "" }}
                </div>

                <sw-tooltip v-if="module!.licensed" tooltip="Licensed module">
                  <v-icon size="x-small">fa-regular fa-check</v-icon>
                </sw-tooltip>
                <v-icon
                  v-if="moduleType === ModuleType.easm && !module!.licensed"
                  class="color-warning"
                  size="x-small"
                >
                  fa-regular fa-warning
                </v-icon>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
  import { PropType } from "vue";

  import {
    ModuleType,
    ScopeModuleConfig,
    CredentialsConfig,
    ScopeModule,
    DarkWebConfig,
    DataLeakageConfig,
    SocialMediaConfig,
  } from "@/typings";
  import SwTooltip from "sweepatic-shared/components/SwTooltip.vue";
  import {
    CONFIGURABLE_MODULES,
    DISABLED_IN_DEMO,
    EDITABLE_MODULES,
    MODULE_LOGO,
    MODULE_TYPE_NAMES,
  } from "./constants";

  const moduleButtonDescriptions: Record<
    keyof typeof ModuleType,
    { enable: string; license?: string }
  > = {
    credentials: {
      enable:
        "Enables the Leaked Credentials module. Having this module disabled will make sure the Leaked Credentials teaser is active. Disabling this module will not remove the associated data that is already present in the scope.",
      license:
        "Indicates if the Leaked Credentials module is being paid for. Enabling a module without having it licensed can be done for demonstration purposes, but should be considered thoroughly before applying. Run this demo for a limited amount of time only.",
    },
    easm: {
      enable:
        "Enables the EASM module. Having this module disabled will make sure the EASM teaser is active. Disabling this module will not remove the associated data that is already present in the scope.",
      license:
        "Indicates if the EASM module is being paid for. Enabling a module without having it licensed can be done for demonstration purposes, but should be considered thoroughly before applying. Run this demo for a limited amount of time only.",
    },
    outscan: {
      enable:
        "Enables the Outscan module. This will sync tags and product info from Portal to EASM. A portal user's information is required.",
    },
    dark_web: {
      enable:
        "Enables the Dark Web module. The Dark Web module should only be enabled for paying customers. Do not enable this for demo purposes.",
      license:
        "Indicates if the Dark Web module is being paid for. Enabling a module without having it licensed can be done for demonstration purposes, but should be considered thoroughly before applying. Run this demo for a limited amount of time only.",
    },
    data_leakage: {
      enable:
        "Enables the Data Leakage module. The Data Leakage module should only be enabled for paying customers. Do not enable this for demo purposes.",
      license:
        "Indicates if the Data Leakage module is being paid for. Enabling a module without having it licensed can be done for demonstration purposes, but should be considered thoroughly before applying. Run this demo for a limited amount of time only.",
    },
    social_media: {
      enable:
        "Enables the Social Media module. The Social Media module should only be enabled for paying customers. Do not enable this for demo purposes.",
      license:
        "Indicates if the Social Media module is being paid for. Enabling a module without having it licensed can be done for demonstration purposes, but should be considered thoroughly before applying. Run this demo for a limited amount of time only.",
    },
    files: {
      enable: "",
      license: "",
    },
  };

  const $emit = defineEmits<{
    (e: "toggleEnabled", moduleType: ModuleType): void;
    (e: "toggleLicensed", payload: { moduleType: ModuleType; module: ScopeModule }): void;
    (e: "edit", payload: { moduleType: ModuleType; module: ScopeModule }): void;
  }>();

  const props = defineProps({
    modules: { type: Object as PropType<ScopeModuleConfig>, required: true },
    scope: { type: String, required: true },
    type: { type: String, required: true },
  });

  const openEdit = (moduleType: ModuleType, module: ScopeModule) => {
    $emit("edit", { moduleType, module });
  };

  const toggleEnable = (moduleType: ModuleType) => {
    if (!moduleType || !props.modules) {
      return;
    }

    $emit("toggleEnabled", moduleType);
  };

  const toggleLicensed = (moduleType: ModuleType, module: ScopeModule) => {
    if (!moduleType || !props.modules) {
      return;
    }

    $emit("toggleLicensed", { moduleType, module });
  };
</script>

<style lang="scss" scoped>
  .edit-btn {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    height: fit-content;
  }

  .disabled {
    border-color: gray;

    .opacity-on-disable {
      opacity: 0.25;
    }
  }
</style>
