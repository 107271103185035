import { App } from "vue";
import { Router } from "vue-router";
import { Store } from "vuex";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import Keycloak from "keycloak-js";

import initSentry from "./sentry";
import { StoreState } from "@/typings/store";
import { setupAxiosInterceptors } from "@/services/axios";
import { captureError } from "@/services/sentry.service";

export default {
  plugin: VueKeyCloak,
  options: (app: App, router: Router, store: Store<StoreState>) => ({
    config: {
      realm: "Sweepatic",
      url: import.meta.env.VITE_KEYCLOAK_URL + "/auth",
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    },
    init: { checkLoginIframe: false, onLoad: "login-required" },
    onReady: (kc: Keycloak) => {
      store.commit("session/setToken", kc.token);

      Promise.all([setupAxiosInterceptors(store, router), initSentry(app, router, kc, store)]).then(
        () => {
          app.mount("#app");
        },
      );
    },

    // something went wrong initializing KC
    onInitError: (error: any) => captureError(error),

    // something went wrong trying to get refresh token
    onAuthRefreshError: (kc: Keycloak) => kc.logout(),

    // token was refreshed successfully, update store
    onAuthRefreshSuccess: (kc: Keycloak) => store.commit("session/setToken", kc.token),
  }),
};
